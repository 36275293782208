import React from "react"
import SearchIcon from "../../assets/SearchIcon"
import { useSearch } from "./SearchContext";

interface Props {
    placeholderText: string;
};

const SearchBox = ({ placeholderText }: Props) => {

    const { appSearchQuery, guideSearchQuery, setAppSearchQuery, setGuideSearchQuery } = useSearch();

    const handleAppSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setAppSearchQuery(event.target.value);
    };

    const handleGuideSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setGuideSearchQuery(event.target.value);
    };

    return (
        <div className="search-container">
            <input type="text" className="search-box" placeholder={placeholderText} value={placeholderText === "Search" ? appSearchQuery : guideSearchQuery} onChange={placeholderText === "Search" ? handleAppSearchChange : handleGuideSearchChange} />
            <span className='search-icon'> <SearchIcon /> </span>
        </div>
    );
}

export default SearchBox;