import React from "react";

interface Props { };

const FolderIcon = (props: Props) => {
    return (
        <svg
            version="1.2"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            overflow="visible"
            preserveAspectRatio="none"
            viewBox="0 0 32 32"
            xmlSpace="preserve"
            y="0px"
            x="0px"
            id="Layer_1"
            width="33"
            height="30"
        >
            <g transform="translate(1, 1)">
                <style type="text/css">
                    {`.st0 {
                        fill: #8e8fa6;
                    }`}
                </style>
                <path
                    d="M23.1,8.8h-8.2V7.7c0-0.8-0.6-1.5-1.4-1.5H6.9c-0.8,0-1.4,0.7-1.4,1.5v14.7c0,0.8,0.6,1.5,1.4,1.5h16.2  c0.8,0,1.4-0.7,1.4-1.5v-12C24.5,9.5,23.9,8.9,23.1,8.8z M22.9,22.2H7.1v-7.7h15.8V22.2z M22.9,12.8H7.1v-5h6.2v2.7h9.6V12.8z"
                    className="st0"
                    vectorEffect="non-scaling-stroke"
                    style={{ fill: "rgb(115, 115, 148)" }}
                />
            </g>
        </svg>
    );
}

export default FolderIcon;