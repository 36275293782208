import React from "react";

interface Props {
  iconClass: string;
  clickHandler: () => void;
};

const DownArrowIcon = ({ iconClass, clickHandler }: Props) => {
  return (
    <svg onClick={clickHandler} className={iconClass} version="1.2" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" overflow="visible" preserveAspectRatio="none" viewBox="0 0 24 24" width="32" height="32"><g><path id="angle-down" d="M17.2,9.84c0-0.09-0.04-0.18-0.1-0.24l-0.52-0.52c-0.13-0.13-0.33-0.14-0.47-0.01c0,0-0.01,0.01-0.01,0.01  l-4.1,4.1l-4.09-4.1C7.78,8.94,7.57,8.94,7.44,9.06c0,0-0.01,0.01-0.01,0.01L6.91,9.6c-0.13,0.13-0.14,0.33-0.01,0.47  c0,0,0.01,0.01,0.01,0.01l4.85,4.85c0.13,0.13,0.33,0.14,0.47,0.01c0,0,0.01-0.01,0.01-0.01l4.85-4.85c0.06-0.06,0.1-0.15,0.1-0.24  l0,0H17.2z" style={{ fill: "rgb(18, 18, 18)" }} vectorEffect="non-scaling-stroke" /></g></svg>
  );
}

export default DownArrowIcon;