import React, { useEffect, useMemo, useRef, useState } from "react";
import { ToastMessageContext } from "./ToastMessageContext";
import "./ToastMessage.css";

function useTimeout(callbackFunction: () => void) {

    const savedCallback = useRef(callbackFunction);

    useEffect(() => {
        savedCallback.current = callbackFunction;
    }, [callbackFunction]);

    useEffect(() => {
        const functionId = setTimeout(() => savedCallback.current(), 3000);
        return () => clearTimeout(functionId);
    }, []);

}

interface Props {
    message: string;
    close: () => void;
};

export default function ToastMessage({ message, close }: Props) {

    useTimeout(() => {
        close();
    });

    return (
        <div className="toast">
            <p>{message}</p>
            <button className="close-button" onClick={close}>
                &#x2715;
            </button>
        </div>
    )
}

interface ToastProviderProperties {
    children: React.ReactElement;
}

interface ToastType {
    message: string;
    id: number;
}

export function ToastProvider({ children }: ToastProviderProperties) {

    const [toasts, setToasts] = useState<ToastType[]>([]);

    function openToast(message: string) {
        const newToast = {
            id: Date.now(),
            message: message
        };
        setToasts((previousToasts) => [...previousToasts, newToast])
    }

    function closeToast(id: number) {
        setToasts((previousToasts) => previousToasts.filter((toast) => toast.id !== id));
    }

    const contextValue = useMemo(() => ({
        open: openToast,
        close: closeToast
    }), [])

    return (
        <>
            <ToastMessageContext.Provider value={contextValue}>
                {children}
                <div className="toasts">
                    {toasts && toasts.map(toast => {
                        return (
                            <ToastMessage key={toast.id} message={toast.message} close={() => closeToast(toast.id)} />
                        );
                    })}
                </div>
            </ToastMessageContext.Provider>
        </>
    );

}