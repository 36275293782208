import React from "react";
import "./AppSelector.css";
import { useSearch } from "../search-box/SearchContext";

interface AppSelectorProps {
    apps: any;
    selectedApp: any;
    isAppSelectorOpen: boolean;
    setIsAppSelectorOpen: React.Dispatch<React.SetStateAction<boolean>>;
    setSelectedFolder: React.Dispatch<React.SetStateAction<number>>;
    setSelectedSubcategory: React.Dispatch<React.SetStateAction<number>>;
    setSelectedAppId: any
}

const AppSelector = ({ apps, selectedApp, isAppSelectorOpen, setIsAppSelectorOpen, setSelectedFolder, setSelectedSubcategory, setSelectedAppId }: AppSelectorProps) => {

    const { setGuideSearchQuery } = useSearch();

    const handleSelect = (appId: any) => {
        setSelectedAppId(appId);
        setGuideSearchQuery("");
        setSelectedFolder(0);
        setSelectedSubcategory(0);
        setIsAppSelectorOpen(false);
    };

    return (
        <div className="app-selector">
            {isAppSelectorOpen && (
                <ul className="app-list">
                    {apps?.map((app: any) => {
                        return (
                            <li className={`app-list-item ${app?.application_id === selectedApp?.application_id ? "selected-app-li" : ""}`} key={app?.application_id} onClick={() => handleSelect(app?.application_id)}>
                                {app.title}
                            </li>
                        );
                    })}
                </ul>
            )}
        </div>
    );
};

export default AppSelector;