import React, { useState, createContext, useContext, useMemo, ReactNode } from "react";

interface SearchContextProps {
    appSearchQuery: string;
    guideSearchQuery: string;
    setAppSearchQuery: (query: string) => void;
    setGuideSearchQuery: (query: string) => void;
}

const SearchContext = createContext<SearchContextProps | undefined>(undefined);

export const SearchProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const [appSearchQuery, setAppSearchQuery] = useState<string>("");
    const [guideSearchQuery, setGuideSearchQuery] = useState<string>("");

    const value = useMemo(
        () => ({
            appSearchQuery,
            guideSearchQuery,
            setAppSearchQuery,
            setGuideSearchQuery,
        }),
        [appSearchQuery, guideSearchQuery]
    );

    return (
        <SearchContext.Provider value={value}>{children}</SearchContext.Provider>
    );
};

export const useSearch = () => {
    const context = useContext(SearchContext);

    if (!context) {
        throw new Error("useSearch must be used within a SearchProvider");
    }

    return context;
};