import React from "react";
import "./LoadingIndicator.css";

interface Props { };

const LoadingIndicator = (props: Props) => {
    return (
        <div className="loader-container">
            <span className="loader"></span>
        </div>
    );
}

export default LoadingIndicator;