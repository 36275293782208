import React, { useState, useEffect } from "react";
import "./Header.css";
import MyGuideLogo from "../../assets/MyGuideLogo";
import MeUserIcon from "../../assets/MeUserIcon";
import { useNavigate, useLocation } from "react-router-dom";
import SearchBox from "../search-box/SearchBox";
import { useSearch } from "../search-box/SearchContext";

interface Props { };

const Header = (props: Props) => {
    const navigate = useNavigate();
    const location = useLocation();
    const [searchPlaceholder, setSearchPlaceholder] = useState("");

    const { setAppSearchQuery } = useSearch();

    useEffect(() => {
        if (location.pathname.match(/^\/categories\/\d+$/)) {
            setSearchPlaceholder('Search guide template');
        } else {
            setSearchPlaceholder('Search');
        }
    }, [location.pathname])

    const handleLogoClick = () => {
        navigate("/");
        setAppSearchQuery("");
    }

    return (
        <header className="header">
            <MyGuideLogo logoClass="logo" clickHandler={handleLogoClick} />

            <SearchBox placeholderText={searchPlaceholder} />

            <select className="language-dropdown">
                <option value="en">English</option>
            </select>

            <MeUserIcon iconClass="me-user-icon" />
        </header>
    );
}

export default Header;