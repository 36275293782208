import React from "react";
import "./AppCard.css";

interface AppCardProps {
    imageUrl: string;
    title: string;
    clickHandler?: () => void;
};

const AppCard = ({ imageUrl, title, clickHandler }: AppCardProps) => {
    return (
        <div className="card" onClick={clickHandler}>
            <img src={imageUrl} alt={title} className="card-image" />
            <h2 className="card-title">{title}</h2>
        </div>
    );
}

export default AppCard;