import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import axios from "axios";
import { useSearch } from "../../components/search-box/SearchContext";
import FolderListing from "../../components/folder-listing/FolderListing";
import FolderDetails from "../../components/folder-details/FolderDetails";
import LoadingIndicator from "../../components/loading-indicator/LoadingIndicator";
import "./AppDetails.css";

interface Props { };

const AppDetails = (props: Props) => {

    const location = useLocation();
    const pathSegments = location.pathname.split('/');
    const applicationIdFromUrl = pathSegments[pathSegments.length - 1];
    const { guideSearchQuery, setGuideSearchQuery } = useSearch();
    const [selectedAppId, setSelectedAppId] = useState<any>(applicationIdFromUrl);
    const [appsList, setAppsList] = useState<any>(null);
    const [isError, setIsError] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [selectedApp, setSelectedApp] = useState<any>();
    const [selectedFolder, setSelectedFolder] = useState<number>(0);
    const [selectedSubcategory, setSelectedSubcategory] = useState<number>(0);
    const [searchResultData, setSearchResultData] = useState<any>();

    useEffect(() => {

        setGuideSearchQuery("");
        setIsError(false);
        setIsLoading(true);

        axios
            .get("../../assets/package/json/org_info.json")
            .then((response: any) => {
                setAppsList(response.data.applications);
            })
            .catch((error: any) => {
                setIsError(true);
                console.error('Error fetching data:', error);
            })
            .finally(() => {
                setIsLoading(false);
            });

    }, []);

    useEffect(() => {

        setIsError(false);
        setIsLoading(true);

        axios
            .get(`../../assets/package/json/app-${selectedAppId}.json`)
            .then((response: any) => {
                const modifiedCategories = response.data.categories.map((category: any) => {
                    const modifiedTours = category?.tours?.filter((tour: any ) => tour.tour_type.includes("walkthrough_tour"));
                    const modifiedSubcategories = category?.sub_categories?.map((sub_category: any) => {
                        const modifiedSubcategoryTours = sub_category?.tours?.filter((subcategoryTour: any ) => subcategoryTour.tour_type.includes("walkthrough_tour"));
                        return {...sub_category, tours: modifiedSubcategoryTours};
                    });
                    return {...category, tours: modifiedTours, sub_categories: modifiedSubcategories};
                })
                setSelectedApp({...response.data, categories: modifiedCategories });
            })
            .catch((error: any) => {
                setIsError(true);
                console.error('Error fetching data:', error);
            })
            .finally(() => {
                setIsLoading(false);
            });

    }, [selectedAppId]);

    useEffect(() => {
        
        const searchResultList = selectedApp?.categories?.flatMap((category: any) => category.tours ?? category.sub_categories.flatMap((subCategory: any) => subCategory.tours))?.filter((searchResultItem: any) => {
            return guideSearchQuery.toLowerCase() === ""
                ? searchResultItem
                : searchResultItem.tour_title.toLowerCase().includes(guideSearchQuery) || searchResultItem.tour_description.toLowerCase().includes(guideSearchQuery)
        });

        setSearchResultData({ tours: searchResultList });

    }, [guideSearchQuery]);

    return (
        <>
            {isLoading && <LoadingIndicator />}

            {isError && <p className="error-msg">Oops! There seems to be an issue. Please try again later.</p>}

            {!isLoading && !selectedApp && <p className="error-msg">Application doesn't exist. Please check the Application ID.</p>}

            <div className="app-details-page-container">
                <div className="folder-listing-container">
                    <FolderListing data={appsList} selectedFolder={selectedFolder} setSelectedFolder={setSelectedFolder} selectedSubcategory={selectedSubcategory} setSelectedSubcategory={setSelectedSubcategory} selectedApp={selectedApp} setSelectedAppId={setSelectedAppId} />
                </div>
                <div className="folder-details-container">
                    {guideSearchQuery === "" && selectedApp?.categories[selectedFolder]?.tours && <FolderDetails data={selectedApp?.categories[selectedFolder]} />}
                    {guideSearchQuery === "" && selectedApp?.categories[selectedFolder]?.sub_categories && <FolderDetails data={selectedApp?.categories[selectedFolder]?.sub_categories[selectedSubcategory]} />}
                    {guideSearchQuery !== "" && <FolderDetails data={searchResultData} />}
                    {(!selectedApp?.categories[selectedFolder]?.tours && !selectedApp?.categories[selectedFolder]?.sub_categories) && <div className="empty-state">There are no categories in this application!</div>}
                </div>
            </div>
        </>
    );
};

export default AppDetails;