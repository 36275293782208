import React, { useCallback, useEffect, useRef } from "react";
import "./PreviewModal.css";
import CloseIcon from "../../assets/CloseIcon";

interface Props {
    showModal: boolean;
    setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
    title: string;
    videoUrl: string;
    handleUseBtnClick: () => void;
};

const PreviewModal = ({ showModal, setShowModal, title, videoUrl, handleUseBtnClick }: Props) => {

    const modalRef = useRef<HTMLDivElement>(null);

    const closeModal = useCallback(() => {
        setShowModal(false);
    }, [setShowModal]);

    const handleEscapeKey = useCallback(
        (event: KeyboardEvent) => {
            if (event.key === 'Escape') {
                closeModal();
            }
        },
        [closeModal]
    );

    useEffect(() => {
        document.addEventListener('keydown', handleEscapeKey);
        return () => {
            document.removeEventListener('keydown', handleEscapeKey);
        };
    }, [closeModal, handleEscapeKey]);

    return (
        <>
            {showModal &&
                (<div ref={modalRef}>
                    <div className={`modal ${showModal ? 'open' : 'closed'}`}>
                        <div className="modal-content">
                            <div className="modal-header">
                                <div className="text-ellipsis">{title}</div>
                                <button className="close" onClick={closeModal}>
                                    <CloseIcon />
                                </button>
                            </div>
                            {videoUrl && <div className="modal-body">
                                <iframe
                                    title="Embedded Video"
                                    width="620"
                                    height="326"
                                    src={videoUrl}
                                    frameBorder="0"
                                    allowFullScreen
                                ></iframe>
                            </div>}
                            {!videoUrl && <div className="preview-not-available">Preview not available</div>}
                            <div className="modal-footer">
                                <button className="modal-footer-button cancel-button" onClick={closeModal}>Cancel</button>
                                <button className="modal-footer-button use-button" onClick={handleUseBtnClick}>Use</button>
                            </div>
                        </div>
                    </div>
                </div> )}
        </>
    );
}

export default PreviewModal;