import React, { useState } from "react";
import "./GuideCard.css";
import PreviewModal from "../preview-modal/PreviewModal";
import { useToast } from "../toast-message/ToastMessageContext";

interface Props {
    imageUrl: string;
    title: string;
    description: string;
    videoUrl: string;
    tourId: number;
};

const GuideCard = ({ imageUrl, title, description, videoUrl, tourId }: Props) => {
    const toast = useToast();
    const [showModal, setShowModal] = useState<boolean>(false);

    const openModal = () => {
        setShowModal(true);
    };

    const handleUseBtnClick = () => {
        // const img = new Image();
        // img.src = "chrome-extension://jpofchnfbdcjkppdmbpdpifjdfcnngbm/common/img/gicon16.png";

       // img.onload = function() {
            let message: any = {
                action:'myguide_action:paste_tour_from_myguide_library',
                mgdata: {
                  tour_id: tourId
                }
            };
            message = JSON.stringify(message);
            window.postMessage(message, "*");
        // };

        // img.onerror = function() {
        //     toast?.open("MyGuide Creator Extension is not installed, please install it.");
        // };
    }

    return (
        <>
            <div className="guide-card">
                <div className="guide-card-image-container">
                    <img src={imageUrl} width={89} height={73} alt="Card" className="guide-card-image" />
                </div>
                <div className="guide-card-content">
                    <p className="guide-card-title">{title}</p>
                    <p className="guide-card-description">{description}</p>
                </div>
                <div className="guide-card-buttons">
                    <button onClick={openModal} className="preview-button">Preview</button>
                    <button onClick={handleUseBtnClick} className="use-button">Use</button>
                </div>
            </div>

            <PreviewModal showModal={showModal} setShowModal={setShowModal} title={title} videoUrl={videoUrl} handleUseBtnClick={handleUseBtnClick} />
        </>

    );
}

export default GuideCard;