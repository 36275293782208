import React from "react";
import ReactDOM from "react-dom/client";
import { RouterProvider } from "react-router-dom";
import { router } from "./routes/Routes";
import { ToastProvider } from "./components/toast-message/ToastMessage";
import { SearchProvider } from "./components/search-box/SearchContext";

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(
  <React.StrictMode>
    <ToastProvider>
      <SearchProvider>
        <RouterProvider router={router} />
      </SearchProvider>
    </ToastProvider>
  </React.StrictMode>
);