import React from "react";
import { createBrowserRouter } from "react-router-dom";
import App from "../App";
import AppsList from "../pages/apps-list/AppsList";
import AppDetails from "../pages/app-details/AppDetails";

export const router = createBrowserRouter([
    {
        element: <App />,
        errorElement: <div>error</div>,
        children: [
            { path: "/", element: <AppsList /> },
            { 
                path: "categories/:appName", 
                element: <AppDetails />
            },
        ]
    }
]);