import React, { useState } from "react";
import "./FolderListing.css";
import FolderIcon from "../../assets/FolderIcon";
import AppsIcon from "../../assets/AppsIcon";
import DownArrowIcon from "../../assets/DownArrowIcon";
import AppSelector from "../app-selector-dropdown/AppSelector";
import { useSearch } from "../search-box/SearchContext";

interface FolderItemProps {
    isSelected?: boolean;
    folderName: string;
    folderDescription: string;
    guideCount: string;
    clickHandler: () => void;
};

interface SubCategoryFolderItemProps {
    isSelected?: boolean;
    folderName: string;
    folderDescription: string;
    guideCount: string;
    clickHandler: () => void;
}

interface SelectedGreenRectangleProps {
    iconClass: string;
};

interface FolderListingProps {
    data: any;
    selectedFolder: number;
    setSelectedFolder: React.Dispatch<React.SetStateAction<number>>;
    selectedSubcategory: number;
    setSelectedSubcategory: React.Dispatch<React.SetStateAction<number>>;
    selectedApp: any;
    setSelectedAppId: any;
};

const SelectedGreenRectangle = ({ iconClass }: SelectedGreenRectangleProps) => {
    return (
        <div className={iconClass}></div>
    );
}

const FolderItem = ({ isSelected, folderName, folderDescription, guideCount, clickHandler }: FolderItemProps) => {
    return (
        <div className={`folder-item ${isSelected ? "folder-item-selected" : ""}`} onClick={clickHandler}>
            <SelectedGreenRectangle iconClass={"green-selected-line"} />
            <div className="folder-icon">
                <FolderIcon />
            </div>
            <div className="folder-details">
                <p className="folder-name">{folderName}</p>
                {folderDescription && <p className={"folder-description"}>{folderDescription}</p>}
                <p className="guide-count">{guideCount}</p>
            </div>
        </div>
    );
}

const SubCategoryFolderItem = ({ isSelected, folderName, folderDescription, guideCount, clickHandler }: SubCategoryFolderItemProps) => {
    return (
        <div className={`folder-item sub-category-folder-item ${isSelected ? "folder-item-selected" : ""}`} onClick={clickHandler}>
            {isSelected && <SelectedGreenRectangle iconClass="green-selected-line" />}
            <div className="sub-category-folder-icon">
                <FolderIcon />
            </div>
            <div className="folder-details">
                <p className="folder-name">{folderName}</p>
                <p className="folder-description">{folderDescription}</p>
                <p className="guide-count">{guideCount}</p>
            </div>
        </div>
    );
}

const FolderListing = ({ data, selectedFolder, setSelectedFolder, selectedSubcategory, setSelectedSubcategory, selectedApp, setSelectedAppId }: FolderListingProps) => {

    const { setGuideSearchQuery } = useSearch();
    const [isAppSelectorOpen, setIsAppSelectorOpen] = useState<boolean>(false);

    const folderItemClickHandler = (selectedIndex: number) => {
        setGuideSearchQuery("");
        setSelectedFolder(selectedIndex);
        setSelectedSubcategory(0);
    }

    const subCategoryItemClickHandler = (selectedIndex: number) => {
        setGuideSearchQuery("");
        setSelectedSubcategory(selectedIndex);
    }

    const folderItems = selectedApp?.categories.map((folderItem: any, folderIndex: number) => {
        const subCategoryItems = folderItem?.sub_categories?.map((subCategoryItem: any, subCategoryIndex: number) => {
            return (
                <SubCategoryFolderItem key={subCategoryItem.category_id} folderName={subCategoryItem.category_title} folderDescription={subCategoryItem.category_description} guideCount={`${subCategoryItem.tours.length} Guides`} isSelected={subCategoryIndex === selectedSubcategory} clickHandler={() => subCategoryItemClickHandler(subCategoryIndex)} />
            );
        })

        return (
            <React.Fragment key={folderItem.category_id}>
                <FolderItem key={folderItem.category_id} folderName={folderItem.category_title} folderDescription={folderItem.category_description} guideCount={folderItem.tours ? `${folderItem.tours.length} Guides` : `${folderItem.sub_categories.length} Sub-Categories`} isSelected={folderIndex === selectedFolder} clickHandler={() => folderItemClickHandler(folderIndex)} />
                {folderIndex === selectedFolder && folderItem.sub_categories && !folderItem.tours && subCategoryItems}
            </React.Fragment>
        );
    })

    return (
        <div className="folder-listing-component-container">
            <div className="app-name-container">
                <AppsIcon iconClass="apps-icon" />
                <div className="app-name">{selectedApp?.title}</div>
                <DownArrowIcon iconClass={`down-arrow-icon ${isAppSelectorOpen ? "down-arrow-app-selector-open" : ""}`} clickHandler={() => setIsAppSelectorOpen(!isAppSelectorOpen)} />
            </div>

            <AppSelector apps={data} selectedApp={selectedApp} isAppSelectorOpen={isAppSelectorOpen} setIsAppSelectorOpen={setIsAppSelectorOpen} setSelectedFolder={setSelectedFolder} setSelectedSubcategory={setSelectedSubcategory} setSelectedAppId={setSelectedAppId} />

            {folderItems}
        </div>
    );
}

export default FolderListing;