import React from "react";
import "./FolderDetails.css";
import GuideCard from "../guide-card/GuideCard";

interface Props {
    data: any;
};

const FolderDetails = ({ data }: Props) => {
    const guideCards = data?.tours?.map((guideCardItem: any) => {
        const dynamicImagePath = guideCardItem?.image_url?.replace(/https?:\/\/127.0.0.1:8080/g, `${process.env.PUBLIC_URL}/assets/package`);
        const dynamicVideoPath = guideCardItem?.video_url?.replace(/https?:\/\/127.0.0.1:8080/g, `${process.env.PUBLIC_URL}/assets/package`);
        return <GuideCard key={guideCardItem.tour_id} imageUrl={dynamicImagePath} title={guideCardItem.tour_title} description={guideCardItem.tour_description} videoUrl={dynamicVideoPath} tourId={guideCardItem.tour_id} />
    })
    return (
        <>
            <p className="folder-details-heading">{data?.category_title ?? "Search Results:"}</p>
            {guideCards?.length > 0 && guideCards}
            {guideCards?.length === 0 && <div className="empty-guide-search-results">Sorry, no guide templates found for your search. Try a different search term or explore the available templates.</div>}
        </>
    );
}

export default FolderDetails;