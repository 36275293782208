import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import AppCard from "../../components/app-card/AppCard";
import LoadingIndicator from "../../components/loading-indicator/LoadingIndicator";
import "./AppsList.css";
import { useSearch } from "../../components/search-box/SearchContext";

interface Props { };

const AppsList = (props: Props) => {

  const navigate = useNavigate();
  const { appSearchQuery, setAppSearchQuery } = useSearch();
  const [apiResponse, setApiResponse] = useState<any>(null);
  const [isError, setIsError] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {

    setAppSearchQuery("");
    setIsError(false);
    setIsLoading(true);

    axios
      .get("./assets/package/json/org_info.json")
      .then((response: any) => {
        setApiResponse(response.data.applications);
      })
      .catch((error: any) => {
        setIsError(true);
        console.error('Error fetching data:', error);
      })
      .finally(() => {
        setIsLoading(false);
      });

  }, []);

  function handleClick(selectedAppId: any) {
    navigate(`/categories/${selectedAppId}`);
  }

  const appCards = apiResponse?.filter((card: any) => {
    return appSearchQuery.toLowerCase() === ""
      ? card
      : card.title.toLowerCase().includes(appSearchQuery.toLowerCase())
  })
    ?.map((card: any) => {
      const dynamicImagePath = card.image_url.replace(/https?:\/\/127.0.0.1:8080/g, `${process.env.PUBLIC_URL}/assets/package`);
      return <AppCard key={card.application_id} imageUrl={dynamicImagePath} title={card.title} clickHandler={() => handleClick(card.application_id)} />
    })

  return (
    <>
      {isLoading && <LoadingIndicator />}
      {isError && <p className="error-msg">Oops! There seems to be an issue. Please try again later.</p>}
      {apiResponse && (<div className="apps-list-page-container">
        <div className="apps-list-container">
          <h2 className="applications-heading-text">Applications</h2>
          <div className="card-container">
            {appCards.length > 0 && appCards}
            {appCards.length === 0 && <div className="empty-app-search-results">Sorry, no applications found for your search. Try a different search term or explore the available applications.</div>}
          </div>
        </div>
      </div>)}
    </>
  );
}

export default AppsList;