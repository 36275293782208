import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import "./Hero.css"

interface Props { };

const Hero = (props: Props) => {

    const location = useLocation();
    const [heroHeading, setHeroHeading] = useState("");

    useEffect(() => {
        if (location.pathname.match(/^\/categories\/[a-zA-Z0-9]+$/)) {
            setHeroHeading("");
        } else {
            setHeroHeading("Guide Templates");
        }
    }, [location.pathname])

    return (
        <section className="hero">
            <div className="hero-text">
                <h1>{heroHeading}</h1>
            </div>
        </section>
    );
}

export default Hero;